import React from "react";
import { TabContent, TabPane } from "reactstrap";

//Import Components
import Profile from "./Tabs/Profile";
import Contacts from "./Tabs/Contacts";
import Settings from "./Tabs/Settings";

export default function ChatLeftSidebar(props) {
  const activeTab = props.activeTab;

  return (
    <React.Fragment>
      <div className="chat-leftsidebar me-lg-1">
        <TabContent activeTab={activeTab}>
          {/* Start Profile tab-pane */}
          <TabPane tabId="profile" id="pills-user">
            {/* profile content  */}
            <Profile userInfoFromDB={props.userInfoFromDB} />
          </TabPane>
          {/* End Profile tab-pane  */}

          {/* Start contacts tab-pane */}
          <TabPane tabId="contacts" id="pills-contacts">
            {/* Contact content */}
            <Contacts userInfoFromDB={props.userInfoFromDB} />
          </TabPane>
          {/* End contacts tab-pane */}
          {/* Start settings tab-pane */}
          <TabPane tabId="settings" id="pills-setting">
            {/* Settings content */}
            <Settings userInfoFromDB={props.userInfoFromDB} />
          </TabPane>
          {/* End settings tab-pane */}
        </TabContent>
        {/* end tab content */}
      </div>
    </React.Fragment>
  );
}
