import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LeftSidebarMenu from "../components/LeftSidebarMenu";
import ChatLeftSidebar from "./ChatLeftSidebar";

import { getFirebaseBackend } from "../database/firebase";
import { AuthContext } from "../Auth";

const Dashboard = () => {
  const firebase = getFirebaseBackend();

  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState("settings");
  const [userInfos, setUserInfos] = useState(null);

  useEffect(() => {
    async function init() {
      const getInfoFromDB = await firebase.getUserInfo(currentUser["userId"]);
      if (getInfoFromDB) {
        setUserInfos(getInfoFromDB);
      } else {
        navigate('/information', { replace: true });
      }
    }
    init();
  }, [])

  return (
    <React.Fragment>
      {userInfos &&
        <div className="layout-wrapper d-lg-flex">
          <LeftSidebarMenu
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            userInfoFromDB={userInfos}
          />
          <ChatLeftSidebar
            activeTab={activeTab}
            userInfoFromDB={userInfos}
          />
        </div>
      }

    </React.Fragment>
  );
}

export default Dashboard;