import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import liff from "@line/liff";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);

    function init() {
        liff
            .init({
                liffId: process.env.REACT_APP_LINE_LIFF_ID,
            })
            .then(async () => {
                if (liff.isLoggedIn()) {
                    const lineProfile = await liff.getProfile();
                    setCurrentUser(lineProfile);
                    setPending(false);
                } else {
                    setPending(false);
                    navigate("/login", { replace: true });
                }
            }).catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        init();
    }, []);

    if (pending) {
        return <Loading />
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const Loading = () => (
    <div className="loading-container">
        <div className="loading">
            <div></div>
            <div></div>
        </div>
    </div>
)