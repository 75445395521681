import React, { useEffect, useState } from "react";
import {
  Nav,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
} from "reactstrap";
import Background from "../../components/Background";
import LeadersImage from "../../assets/images/leaders/03.png";

import { getFirebaseBackend } from "../../database/firebase";

export default function Settings(props) {
  const firebase = getFirebaseBackend();
  const userInfoFromDB = props.userInfoFromDB;
  const now = new Date();

  const [centralZoneRenderCondition, setCentralZoneRenderCondition] = useState(false);

  const [openDaimoku, setOpenDaimoku] = useState(false);
  const [openMember, setOpenMember] = useState(false);
  const [openMemberInfo, setOpenMemberInfo] = useState(false);
  const [openLeaders, setOpenLeaders] = useState(now.getDate() < 10);
  const [openAddName, setOpenAddName] = useState(false);

  const [addDaimuku, setAddDaimoku] = useState(0);
  const [addInviteName, setAddInviteName] = useState("");
  const [addInviteAmount, setAddInviteAmount] = useState(0);

  const [displayDaimuku, setDisplayDaimoku] = useState(0);
  const [displayInviteAmount, setDisplayInviteAmount] = useState(0);

  const [firstname, setFirstname] = React.useState(null);
  const [lastname, setLastname] = React.useState(null);
  const [division, setDivision] = React.useState("ยุวชนชาย");

  const toggleDaimoku = () => {
    setOpenDaimoku(!openDaimoku);
  };

  const toggleMember = () => {
    setOpenMember(!openMember);
  };

  const toggleMemberInfo = () => {
    setOpenMemberInfo(!openMemberInfo);
  };

  const toggleLeaders = () => {
    setOpenLeaders(!openLeaders);
  };

  const toggleAddName = () => {
    setOpenAddName(!openAddName);
  };

  useEffect(() => {
    if (userInfoFromDB) {
      // Set User Default Value
      setDisplayInviteAmount(userInfoFromDB.inviteAmount);
      setDisplayDaimoku(userInfoFromDB.daimoku)
      // Central Zone Render Condition
      setCentralZoneRenderCondition(!userInfoFromDB.rumpak);
      if (userInfoFromDB.rumpak) {
        setCentralZoneRenderCondition(userInfoFromDB.rumpak.includes("กลาง"));
      }

      // Central Zone Additional Info
      if (userInfoFromDB.firstname == undefined || userInfoFromDB.division == undefined) {
        setOpenAddName(true);
      }
    }
  }, [userInfoFromDB]);

  return (
    <React.Fragment>
      <Background />
      {userInfoFromDB && (
        <div>
          <div className="px-4 pt-4">
            <h4 className="mb-0">
              {"บันทึกข้อมูลรณรงค์การชักชวนแนะนำธรรม ยุวชนประเทศไทย"}
            </h4>
          </div>

          <div className="text-center border-bottom p-4">
            <div className="mb-2 profile-user">
              <img
                src={userInfoFromDB.profileImage}
                className="rounded-circle avatar-lg img-thumbnail"
                alt="chatvia"
              />
            </div>

            <h5 className="font-size-16 mb-1 text-truncate">
              {userInfoFromDB.userName}
            </h5>
          </div>
          {/* End profile user */}
          <div className="flex-lg-column my-auto pt-2 border-bottom">
            <Nav
              pills
              className="side-menu-nav justify-content-around"
              role="tablist"
            >
              {centralZoneRenderCondition &&
                <div className="py-2 text-center">
                  <p>ยอดไดโมขุ มิ.ย.-ก.ค.</p>
                  <h1 className="number-huge">{displayDaimuku}</h1>
                  <p className="m-0">ช่อง</p>
                </div>
              }

              <div className="py-2 text-center">
                <p>ชักชวนแนะนำธรรม มิ.ย.-ก.ค.</p>
                <h1 className="number-huge">{displayInviteAmount}</h1>
                <p className="m-0">คน</p>
              </div>
            </Nav>
          </div>

          {/* Central Zone Leader Board */}
          {centralZoneRenderCondition &&
            <div className="d-grid px-4 pt-3">
              <Button
                onClick={toggleLeaders}
                color="info"
                block
                className="full waves-effect waves-light"
              >
                {"แสดงยอดรวมการต่อสู้ มี.ค."}
              </Button>
            </div>
          }

          <div className="d-grid px-4 py-3">
            <Button
              onClick={toggleMemberInfo}
              block
              className="full waves-effect waves-light"
            >
              {"บันทึกรายชื่อผู้ถูกแนะนำ"}
            </Button>
          </div>

          {/* Daimoku Button */}
          {centralZoneRenderCondition &&
            <div className="d-grid pb-3 px-4 border-bottom">
              <Button
                onClick={() => {
                  toggleDaimoku();
                  setAddDaimoku(0);
                }}
                color="primary"
                block
                className=" waves-effect waves-light"
              >
                {"กรอกยอดไดโมขุ"}
              </Button>
            </div>
          }

          {/* Invite Amount Button */}
          <div className="d-grid px-4 pt-3">
            <Button
              onClick={() => {
                toggleMember();
                setAddInviteAmount(0);
              }}
              color="warning"
              block
              className="full waves-effect waves-light"
            >
              {"กรอกจำนวนการแนะนำธรรม"}
            </Button>
          </div>

          {/* Start Leaders Modal */}
          {centralZoneRenderCondition &&
            <Modal isOpen={openLeaders} centered toggle={toggleLeaders}>
              <ModalHeader
                tag="h5"
                className="modal-title font-size-14"
                toggle={toggleLeaders}
              >
                {"Hall of Frame | มี.ค. | ภูมิภาคกลาง"}
              </ModalHeader>
              <ModalBody className="p-4">
                <img src={LeadersImage} width="100%" />
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="primary"
                  onClick={toggleLeaders}
                >
                  ปิด
                </Button>
              </ModalFooter>
            </Modal>
          }
          {/* End Leaders Modal */}

          {/* Start Daimoku Modal */}
          <Modal isOpen={openDaimoku} centered toggle={toggleDaimoku}>
            <ModalHeader
              tag="h5"
              className="modal-title font-size-14"
              toggle={toggleDaimoku}
            >
              {"บันทึกยอดไดโมขุ"}
            </ModalHeader>
            <ModalBody className="p-4">
              <Form>
                <div className="mb-4">
                  <Label className="form-label" htmlFor="add-daimoku-input">
                    {"ระบุจำนวนช่องไดโมขุ 20 นาที = 1 ช่อง"}
                  </Label>
                  <Input
                    type="tel"
                    className="form-control"
                    id="add-daimoku-input"
                    placeholder=""
                    onChange={(e) => {
                      if (!isNaN(parseInt(e.target.value))) {
                        setAddDaimoku(e.target.value);
                      }
                    }}
                  />
                </div>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="link" onClick={toggleDaimoku}>
                {"ปิด"}
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={async () => {
                  await firebase.recordDaimoku(
                    userInfoFromDB.lineId,
                    parseInt(addDaimuku)
                  );
                  toggleDaimoku();
                  // window.location.reload();
                  setDisplayDaimoku(displayDaimuku + parseInt(addDaimuku));
                }}
              >
                บันทึก
              </Button>
            </ModalFooter>
          </Modal>
          {/* End Daimoku Modal */}

          {/* Start Invite Amount Modal */}
          <Modal isOpen={openMember} centered toggle={toggleMember}>
            <ModalHeader
              tag="h5"
              className="modal-title font-size-14"
              toggle={toggleMember}
            >
              {"บันทึกยอดแนะนำธรรม"}
            </ModalHeader>
            <ModalBody className="p-4">
              <Form>
                <div className="mb-4">
                  <Label className="form-label">
                    {"ระบุจำนวนคนที่เราชักชวนแนะนำธรรมสำเร็จ (คน)"}
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="add-invite-amount-input"
                    placeholder=""
                    onChange={(e) => {
                      if (!isNaN(parseInt(e.target.value))) {
                        setAddInviteAmount(parseInt(e.target.value))
                      }
                    }}
                  />
                  <p className="mt-1">คุณสามารถลบข้อมูลหากระบุตัวเลขผิด เช่น -5 (ลบออกห้าคน)</p>
                </div>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="link" onClick={toggleMember}>
                {"ปิด"}
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={async () => {
                  await firebase.recordInviteAmount(
                    userInfoFromDB.lineId,
                    addInviteAmount
                  );
                  toggleMember();
                  // window.location.reload();
                  setDisplayInviteAmount(displayInviteAmount + parseInt(addInviteAmount));
                }}
              >
                บันทึก
              </Button>
            </ModalFooter>
          </Modal>
          {/* End Invite Amount Modal */}

          {/* Start Member Info Modal */}
          <Modal isOpen={openMemberInfo} centered toggle={toggleMemberInfo}>
            <ModalHeader
              tag="h5"
              className="modal-title font-size-14"
              toggle={toggleMemberInfo}
            >
              {"บันทึกข้อมูลผู้ถูกแนะนำธรรม"}
            </ModalHeader>
            <ModalBody className="p-4">
              <Form>
                <div className="mb-4">
                  <Label className="form-label">{"ชื่อ นามสกุล*"}</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) =>
                      setAddInviteName({
                        ...addInviteName,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <Label className="form-label">{"ชื่อเล่น"}</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) =>
                      setAddInviteName({
                        ...addInviteName,
                        nickname: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <Label className="form-label">{"เบอร์โทร"}</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) =>
                      setAddInviteName({
                        ...addInviteName,
                        tel: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <Label className="form-label">{"อายุ"}</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) =>
                      setAddInviteName({
                        ...addInviteName,
                        age: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <Label className="form-label">{"ที่อยู่"}</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) =>
                      setAddInviteName({
                        ...addInviteName,
                        address: e.target.value,
                      })
                    }
                  />
                </div>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="link" onClick={toggleMemberInfo}>
                {"ปิด"}
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={async () => {
                  if (addInviteName.name) {
                    await firebase.recordInviteName(
                      userInfoFromDB.lineId,
                      addInviteName
                    );
                    toggleMemberInfo();
                    window.location.reload();
                  }
                }}
              >
                บันทึก
              </Button>
            </ModalFooter>
          </Modal>
          {/* End Member Info Modal */}

          {/* Start Add Name Modal */}
          <Modal isOpen={openAddName} centered toggle={toggleAddName}>
            <ModalHeader
              tag="h5"
              className="modal-title font-size-14"
              toggle={toggleAddName}
            >
              {"กรุณากรอกข้อมูลเพิ่มเติม"}
            </ModalHeader>
            <ModalBody className="p-4">
              <Form>
                <div className="mb-4">
                  <Label className="form-label">{"ชื่อจริง*"}</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) =>
                      setFirstname(e.target.value)
                    }
                  />
                </div>
                <div className="mb-4">
                  <Label className="form-label">{"นามสกุล*"}</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder=""
                    onChange={(e) =>
                      setLastname(e.target.value)
                    }
                  />
                </div>
                <div className="mb-4">
                  <Label className="form-label">{"ฝ่าย*"}</Label>
                  <Input
                    type="select"
                    className="form-control"
                    placeholder=""
                    onChange={(e) =>
                      setDivision(e.target.value)
                    }
                  >
                    <option key={"ยุวชนชาย"} value={"ยุวชนชาย"}>
                      {"ยุวชนชาย"}
                    </option>
                    <option key={"ยุวชนหญิง"} value={"ยุวชนหญิง"}>
                      {"ยุวชนหญิง"}
                    </option>
                  </Input>
                </div>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="primary"
                onClick={async () => {
                  if (firstname && lastname && division) {
                    await firebase.updateFirstname(
                      userInfoFromDB.lineId,
                      firstname
                    );
                    await firebase.updateLastname(
                      userInfoFromDB.lineId,
                      lastname
                    );
                    await firebase.updateDivision(
                      userInfoFromDB.lineId,
                      division
                    );
                    toggleAddName();
                  }
                }}
              >
                บันทึก
              </Button>
            </ModalFooter>
          </Modal>
          {/* End Add Name Modal */}
        </div>
      )}
    </React.Fragment>
  );
}
