
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom"

import Login from "./Login";
import Register from "./Register";
import Dashboard from "./dashboard";
import { PrivateRoute } from "./routes/route";

import "./App.css";
import "./assets/scss/themes.scss";
import { initFirebaseBackend } from "./database/firebase";

import { AuthProvider } from "./Auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

initFirebaseBackend(firebaseConfig);

export default function App() {
  return (
    <React.Fragment>
      <AuthProvider>
        <div className="App">
          <Routes>
            {/* PRIVATE PAGES */}
            <Route element={<PrivateRoute />}>
              <Route path='/' element={<Navigate to="/dashboard" />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/information' element={<Register />} />
            </Route>

            {/* PUBLIC PAGES */}
            <Route path='/login' element={<Login />} />
          </Routes>
        </div>
      </AuthProvider>
    </React.Fragment>
  );
}
