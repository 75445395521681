import React, { useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
  InputGroup,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import Background from "../../components/Background";
import { getFirebaseBackend } from "../../database/firebase";

export default function Contacts(props) {
  const firebase = getFirebaseBackend();
  const userInfoFromDB = props.userInfoFromDB;

  // const contacts = [
  //   { id: 1, name: "นิรมัท วรารักษ์" },
  //   { id: 2, name: "ปริรม เจริญสกุล" },
  //   { id: 3, name: "โสธร ศิริภาพันธ์" },
  //   { id: 4, name: "ปภาวดี คมปราชญ์" },
  //   { id: 5, name: "ไรยา โตศิลา" },
  //   { id: 6, name: "ไรยา วงศ์เจริญ" },
  //   { id: 7, name: "พัชรวรรธ ศรีทอง" },
  //   { id: 8, name: "พร้อมพงศ์ โอภาสพงส์" },
  //   { id: 9, name: "นวิยา เมธากิจขจร" },
  //   { id: 10, name: "พัทธมน ทรัพย์ศิลา" },
  //   { id: 11, name: "โอภาส โอภาสเจริญ" },
  //   { id: 12, name: "ภาวดี เมธากิจขจร" },
  //   { id: 13, name: "ธนภัทร บำรุงกาญจน์" },
  //   { id: 14, name: "รชานนท์ พัฒน์ธนโกศล" },
  //   { id: 15, name: "พริมา วิเชียรชาญ" },
  //   { id: 16, name: "ธีภพ เดชบุญ" },
  //   { id: 17, name: "รัตน์ ศรีทอง" },
  //   { id: 18, name: "นเรศ พันธ์ภูผา" },
  // ];

  // const [contacts, setContacts] = useState([]);
  const [sortedContacts, setSortedContacts] = useState(null);

  const sortContact = (contacts) => {
    let data = contacts.reduce((r, e) => {
      try {
        // get first letter of name of current element
        let group = e.name[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] };
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
      } catch (error) {
        return sortedContacts;
      }
      // return accumulator
      return r;
    }, {});

    let result = Object.values(data);
    setSortedContacts(result);
    return result;
  };

  useEffect(() => {
    if (userInfoFromDB) {
      let contacts = userInfoFromDB.inviteName.map((e, i) => {
        return {
          id: i,
          name: e.name,
          nickname: e.nickname,
          tel: e.tel,
          age: e.age,
          address: e.address,
        };
      });

      sortContact(contacts);
    }
  }, [userInfoFromDB]);

  return (
    <React.Fragment>
      <Background />
      <div>
        <div className="p-4">
          <h4 className="mb-4">รายชื่อผู้ถูกแนะนำ (เรียงตามอักษร)</h4>
        </div>
        {/* end p-4 */}

        {/* Start contact lists */}
        <SimpleBar
          style={{ maxHeight: "100%" }}
          id="chat-room"
          className="px-4 chat-message-list chat-group-list"
        >
          {sortedContacts &&
            sortedContacts.map((contact, key) => (
              <div key={key} className={key + 1 === 1 ? "" : "mt-3"}>
                <div className="p-3 fw-bold text-primary">{contact.group}</div>

                <ul className="list-unstyled contact-list">
                  {contact.children.map((child, key) => (
                    <li key={key}>
                      <div className="d-flex align-items-center">
                        <div className="flex-1 py-2 border-bottom">
                          <h5 className="font-size-16 mb-3">{child.name}</h5>
                          {child.nickname && (
                            <div className="font-size-14 invite-subinfo">
                              <p>ชื่อเล่น</p>
                              <p>{child.nickname}</p>
                            </div>
                          )}
                          {child.tel && (
                            <div className="font-size-14 invite-subinfo">
                              <p>เบอร์โทร</p>
                              {child.tel}
                            </div>
                          )}
                          {child.age && (
                            <div className="font-size-14 invite-subinfo">
                              <p>อายุ</p>
                              {child.age}
                            </div>
                          )}
                          {child.address && (
                            <div className="font-size-14 invite-subinfo">
                              <p>ที่อยู่</p>
                              {child.address}
                            </div>
                          )}
                        </div>
                        {/* <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="text-muted">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem>
                              {"Share"}{" "}
                              <i className="ri-share-line float-end text-muted"></i>
                            </DropdownItem>
                            <DropdownItem>
                              {"Block"}{" "}
                              <i className="ri-forbid-line float-end text-muted"></i>
                            </DropdownItem>
                            <DropdownItem>
                              {"Remove"}{" "}
                              <i className="ri-delete-bin-line float-end text-muted"></i>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown> */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </SimpleBar>
        {/* end contact lists */}
      </div>
    </React.Fragment>
  );
}
