import React, { useEffect, useState, useContext } from "react";

import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Button,
  FormFeedback,
  Label,
  InputGroup,
} from "reactstrap";

import { getFirebaseBackend } from "./database/firebase";

//Import Images
import logodark from "./assets/images/CR_Logo.jpg";
import Background from "./components/Background";

// Data
import zoneData from "./data/sgt-data.json";
import { AuthContext } from "./Auth";

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers, 
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

/**
 * Register component
 * @param {*} props
 */

const Register = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const firebase = getFirebaseBackend();

  const [firstname, setFirstname] = React.useState(null);
  const [lastname, setLastname] = React.useState(null);
  const [division, setDivision] = React.useState(null);
  const [pak, setPak] = React.useState(null);
  const [rumpak, setRumpak] = React.useState(null);
  const [kate, setKate] = React.useState(null);
  const [optionRumpaks, setOptionRumpaks] = useState(null);

  // validation
  const formik = useFormik({
    initialValues: {
      rumpak: "ระบุรวมภาค",
      pak: "ระบุภาค",
      kate: "ระบุเขต",
      firstname: "",
      lastname: "",
      division: "ระบุฝ่าย",
    },
    validationSchema: Yup.object({
      rumpak: Yup.string().required("กรุณาระบุข้อมูล"),
      pak: Yup.string().required("กรุณาระบุข้อมูล"),
      kate: Yup.string().required("กรุณาระบุข้อมูล"),
      firstname: Yup.string().required("กรุณาระบุข้อมูล"),
      lastname: Yup.string().required("กรุณาระบุข้อมูล"),
    }),
    onSubmit: async (values) => {
      // console.log("currentUser", currentUser)
      if (rumpak && pak && kate && firstname && lastname && division) {
        let fRumpak = JSON.parse(values.rumpak);
        let fPak = JSON.parse(values.pak);
        let fKate = JSON.parse(values.kate);
        await firebase.registerUser(
          currentUser["userId"],
          currentUser["displayName"],
          currentUser["pictureUrl"] + "/large",
          fRumpak["ชื่อไทย - รวมภาค"],
          fPak["ชื่อไทย - ภาค"],
          fKate["ชื่อไทย - เขต"],
          values.firstname,
          values.lastname,
          values.division,
        );

        // setIsMember(true);
        // window.location.reload();
        navigate("/dashboard", { replace: true });
      }
    },
  });

  const rumpakItems = () => {
    let decodeRumpaks = zoneData.filter(e => e["name"] === "addr_zone");
    // rumpaks = [...new Set(rumpaks[0]["data"])];
    let rumpaks = decodeRumpaks[0]["data"];
    rumpaks.sort(dynamicSort("ชื่อไทย - รวมภาค"));
    // console.log("rumpaks", rumpaks);
    rumpaks.unshift({ "ชื่อไทย - รวมภาค": "ระบุรวมภาค" });

    let options = rumpaks.map((e) => (
      <option key={e["ชื่อไทย - รวมภาค"]} value={JSON.stringify(e)}>
        {e["ชื่อไทย - รวมภาค"]}
      </option>
    ));

    setOptionRumpaks(options);
  };

  const pakItems = () => {
    let decodePaks = zoneData.filter(e => e["name"] === "addr_headquarter");

    let paks = decodePaks[0]["data"];
    paks = paks.filter((e) => e["fk - รวมภาค"] === rumpak["id - รวมภาค"]);
    // console.log("paks", paks);
    // paks = [...new Set(paks)];
    paks.unshift({ "ชื่อไทย - ภาค": "ระบุภาค" });

    return paks.map((e) => (
      <option key={e["ชื่อไทย - ภาค"]} value={JSON.stringify(e)}>
        {e["ชื่อไทย - ภาค"]}
      </option>
    ));
  };

  const kateItems = () => {
    let decodeKates = zoneData.filter(e => e["name"] === "addr_chapter");

    let kates = decodeKates[0]["data"];
    kates = kates.filter((e) => e["fk - ภาค"] === pak["id - ภาค"]);
    // console.log("kates", kates);
    // kates = [...new Set(kates)];
    kates.unshift({ "ชื่อไทย - เขต": "ระบุเขต" });

    return kates.map((e) => (
      <option key={e["ชื่อไทย - เขต"]} value={JSON.stringify(e)}>
        {e["ชื่อไทย - เขต"]}
      </option>
    ));
  };

  useEffect(() => {
    rumpakItems();
  }, []);

  return (
    <React.Fragment>
      <Background />
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="text-center mb-4">
                <div className="auth-logo mb-5 d-block">
                  <img
                    src={logodark}
                    alt=""
                    height="150"
                    className="logo logo-dark"
                  />
                </div>

                <h4>{"โครงการรณรงค์แนะนำธรรมยุวชนประเทศไทย"}</h4>
                <p className="text-muted mb-4">
                  {"ลงทะเบียนเพื่อเข้าสู่ระบบบันทึกข้อมูล"}.
                </p>
              </div>

              <Card>
                <CardBody className="p-4">
                  {/* {props.error && (
                      <Alert variant="danger">{props.error}</Alert>
                    )}
                    {props.user && (
                      <Alert variant="success">
                        ลงทะเบียนบัญชีของคุณสำเร็จ!
                      </Alert>
                    )} */}
                  <div className="p-3">
                    <Form onSubmit={formik.handleSubmit}>
                      <FormGroup className="mb-4">
                        <Label className="form-label">{"ชื่อจริง"}</Label>
                        <InputGroup className="mb-3 bg-soft-light input-group-lg rounded-lg">
                          <span className="input-group-text border-light text-muted">
                            <i className="ri-lock-2-line"></i>
                          </span>
                          <Input
                            id="firstname"
                            name="firstname"
                            className="form-control form-control-lg bg-soft-light border-light"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setFirstname(e.target.value);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstname}
                            invalid={
                              formik.touched.firstname && formik.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {formik.touched.firstname && formik.errors.firstname ? (
                            <FormFeedback type="invalid">
                              {formik.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="form-label">{"นามสกุล"}</Label>
                        <InputGroup className="mb-3 bg-soft-light input-group-lg rounded-lg">
                          <span className="input-group-text border-light text-muted">
                            <i className="ri-lock-2-line"></i>
                          </span>
                          <Input
                            id="lastname"
                            name="lastname"
                            className="form-control form-control-lg bg-soft-light border-light"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setLastname(e.target.value);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastname}
                            invalid={
                              formik.touched.lastname && formik.errors.lastname
                                ? true
                                : false
                            }
                          />
                          {formik.touched.lastname && formik.errors.lastname ? (
                            <FormFeedback type="invalid">
                              {formik.errors.lastname}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="form-label">{"ฝ่าย"}</Label>
                        <InputGroup className="mb-3 bg-soft-light input-group-lg rounded-lg">
                          <span className="input-group-text border-light text-muted">
                            <i className="ri-lock-2-line"></i>
                          </span>
                          <Input
                            type="select"
                            id="division"
                            name="division"
                            className="form-control form-control-lg bg-soft-light border-light"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setDivision(e.target.value);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.division}
                            invalid={
                              formik.touched.division && formik.errors.division
                                ? true
                                : false
                            }
                          >
                            <option key={"ระบุฝ่าย"} value={"ระบุฝ่าย"}>
                              {"ระบุฝ่าย"}
                            </option>
                            <option key={"ยุวชนชาย"} value={"ยุวชนชาย"}>
                              {"ยุวชนชาย"}
                            </option>
                            <option key={"ยุวชนหญิง"} value={"ยุวชนหญิง"}>
                              {"ยุวชนหญิง"}
                            </option>
                          </Input>
                          {formik.touched.division && formik.errors.division ? (
                            <FormFeedback type="invalid">
                              {formik.errors.division}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="form-label">{"รวมภาค"}</Label>
                        <InputGroup className="mb-3 bg-soft-light input-group-lg rounded-lg">
                          <span className="input-group-text border-light text-muted">
                            <i className="ri-lock-2-line"></i>
                          </span>
                          <Input
                            type="select"
                            id="rumpak"
                            name="rumpak"
                            className="form-control form-control-lg bg-soft-light border-light"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setRumpak(JSON.parse(e.target.value));
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.rumpak}
                            invalid={
                              formik.touched.rumpak && formik.errors.rumpak
                                ? true
                                : false
                            }
                          >
                            {optionRumpaks}
                          </Input>
                          {formik.touched.rumpak && formik.errors.rumpak ? (
                            <FormFeedback type="invalid">
                              {formik.errors.rumpak}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="form-label">{"ภาค"}</Label>
                        <InputGroup className="mb-3 bg-soft-light input-group-lg rounded-lg">
                          <span className="input-group-text border-light text-muted">
                            <i className="ri-lock-2-line"></i>
                          </span>
                          <Input
                            type="select"
                            id="pak"
                            name="pak"
                            className="form-control form-control-lg bg-soft-light border-light"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setPak(JSON.parse(e.target.value));
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.pak}
                            invalid={
                              formik.touched.pak && formik.errors.pak
                                ? true
                                : false
                            }
                          >
                            {rumpak && pakItems()}
                          </Input>
                          {formik.touched.pak && formik.errors.pak ? (
                            <FormFeedback type="invalid">
                              {formik.errors.pak}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="form-label">{"เขต"}</Label>
                        <InputGroup className="mb-3 bg-soft-light input-group-lg rounded-lg">
                          <span className="input-group-text border-light text-muted">
                            <i className="ri-lock-2-line"></i>
                          </span>
                          <Input
                            type="select"
                            id="kate"
                            name="kate"
                            className="form-control form-control-lg bg-soft-light border-light"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setKate(JSON.parse(e.target.value));
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.kate}
                            invalid={
                              formik.touched.kate && formik.errors.kate
                                ? true
                                : false
                            }
                          >
                            {pak && kateItems()}
                          </Input>
                          {formik.touched.kate && formik.errors.kate ? (
                            <FormFeedback type="invalid">
                              {formik.errors.kate}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </FormGroup>

                      <div className="d-grid">
                        <Button
                          color="primary"
                          block
                          className=" waves-effect waves-light"
                          type="submit"
                        >
                          ลงทะเบียน
                        </Button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <p className="text-muted mb-0">{('By registering you agree to the Chatvia')} <Link to="#" className="text-primary">{('Terms of Use')}</Link></p>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>{"© Soka Gakkai Thailand 2022. All Rights Reserved."}</p>
              </div>

              {/* <Button
                  block
                  className=" waves-effect waves-light"
                  onClick={() => liff.logout()}
                >
                  Logout
                </Button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Register;