import React from "react";
import { Nav, NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";

//Import Images
import logo from "../assets/images/CR_Logo.jpg";
import liff from "@line/liff/dist/lib";

export default function LeftSidebarMenu(props) {
  const userInfoFromDB = props.userInfoFromDB;
  const toggleTab = (tab) => {
    props.setActiveTab(tab);
  };

  const activeTab = props.activeTab;

  return (
    <React.Fragment>
      {userInfoFromDB && (
        <div className="side-menu flex-lg-column me-lg-1">
          {/* LOGO */}
          <div className="navbar-brand-box">
            <div className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo} alt="logo" height="30" />
              </span>
            </div>

            <div className="logo logo-light">
              <span className="logo-sm">
                <img src={logo} alt="logo" height="30" />
              </span>
            </div>
          </div>
          {/* end navbar-brand-box  */}

          {/* Start side-menu nav */}
          <div className="flex-lg-column my-auto">
            <Nav
              pills
              className="side-menu-nav justify-content-center"
              role="tablist"
            >
              <NavItem id="Settings">
                <NavLink
                  id="pills-setting-tab"
                  className={classnames({ active: activeTab === "settings" })}
                  onClick={() => {
                    toggleTab("settings");
                  }}
                >
                  <i className="ri-message-3-line"></i>
                </NavLink>
              </NavItem>
              <UncontrolledTooltip target="Settings" placement="top">
                หน้าหลัก
              </UncontrolledTooltip>

              <NavItem id="Contacts">
                <NavLink
                  id="pills-contacts-tab"
                  className={classnames({ active: activeTab === "contacts" })}
                  onClick={() => {
                    toggleTab("contacts");
                  }}
                >
                  <i className="ri-contacts-line"></i>
                </NavLink>
              </NavItem>
              <UncontrolledTooltip target="Contacts" placement="top">
                รายชื่อ
              </UncontrolledTooltip>

              {/* <NavItem id="Logout">
                <NavLink
                  id="pills-logout-tab"
                  onClick={() => {
                    liff.logout();
                    window.location.reload();
                  }}
                >
                  <i className="ri-logout-circle-r-line"></i>
                </NavLink>
              </NavItem>
              <UncontrolledTooltip target="Logout" placement="top">
                ออกจากระบบ
              </UncontrolledTooltip> */}

              <NavItem id="profile">
                <NavLink
                  id="pills-user-tab"
                  className={classnames({ active: activeTab === "profile" })}
                  onClick={() => {
                    toggleTab("profile");
                  }}
                >
                  <img
                    src={userInfoFromDB.profileImage}
                    alt="chatvia"
                    className="profile-user rounded-circle"
                  />
                </NavLink>
              </NavItem>
              <UncontrolledTooltip target="profile" placement="top">
                โปรไฟล์
              </UncontrolledTooltip>
            </Nav>
          </div>
          {/* end side-menu nav */}

          <div className="flex-lg-column d-none d-lg-block">
            <Nav className="side-menu-nav justify-content-center"></Nav>
          </div>
          {/* Side menu user */}
        </div>
      )}
    </React.Fragment>
  );
}
