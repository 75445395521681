import React, { useState } from "react";
import { Card } from "reactstrap";
import SimpleBar from "simplebar-react";
import Background from "../../components/Background";

import CustomCollapse from "../../components/CustomCollapse";

export default function Profile(props) {
  const userInfoFromDB = props.userInfoFromDB;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);

  const toggleCollapse1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
  };

  return (
    <React.Fragment>
      <Background />
      {userInfoFromDB && (
        <div>
          <div className="px-4 pt-4">
            <h4 className="mb-0">{"โปรไฟล์ของฉัน"}</h4>
          </div>

          <div className="text-center p-4 border-bottom">
            <div className="mb-4">
              <img
                src={userInfoFromDB.profileImage}
                className="rounded-circle avatar-lg img-thumbnail"
                alt="chatvia"
              />
            </div>

            <h5 className="font-size-16 mb-1 text-truncate">
              {userInfoFromDB.userName}
            </h5>
            <p className="text-muted text-truncate mb-1">
              <i className="ri-record-circle-fill font-size-10 text-success me-1 d-inline-block"></i>{" "}
              {"Active"}
            </p>
          </div>
          {/* End profile user  */}

          {/* Start user-profile-desc */}
          <div className="p-4 user-profile-desc">
            {/* <div className="text-muted">
          <p className="mb-4">{('If several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual.')}</p>
        </div> */}

            <SimpleBar
              style={{ maxHeight: "100%" }}
              id="profile"
              className="px-4"
            >
              <div id="profile-user-accordion-1" className="custom-accordion">
                <Card className="shadow-none border mb-2">
                  {/* import collaps */}
                  <CustomCollapse
                    title="ข้อมูลส่วนตัว"
                    iconClass="ri-user-2-line"
                    isOpen={isOpen1}
                    toggleCollapse={toggleCollapse1}
                  >
                    <div>
                      <p className="text-muted mb-1">{"ชื่อ"}</p>
                      <h5 className="font-size-14">
                        {userInfoFromDB.userName}
                      </h5>
                    </div>

                    {/* <div className="mt-4">
                  <p className="text-muted mb-1">{"อีเมล"}</p>
                  <h5 className="font-size-14">{"adc@123.com"}</h5>
                </div> */}

                    {/* <div className="mt-4">
                  <p className="text-muted mb-1">{"รวมภาค"}</p>
                  <h5 className="font-size-14">{"กลาง 2"}</h5>
                </div> */}

                    <div className="mt-4">
                      <p className="text-muted mb-1">{"รวมภาค"}</p>
                      <h5 className="font-size-14 mb-0">
                        {userInfoFromDB.rumpak ? userInfoFromDB.rumpak : "ยังไม่ระบุ"}
                      </h5>
                    </div>

                    <div className="mt-4">
                      <p className="text-muted mb-1">{"ภาค"}</p>
                      <h5 className="font-size-14 mb-0">
                        {userInfoFromDB.pak}
                      </h5>
                    </div>

                    <div className="mt-4">
                      <p className="text-muted mb-1">{"เขต"}</p>
                      <h5 className="font-size-14 mb-0">
                        {userInfoFromDB.kate}
                      </h5>
                    </div>
                  </CustomCollapse>
                </Card>
                {/* End About card  */}

                {/* <Card className="mb-1 shadow-none border">

                                      <CustomCollapse
                                          title = "Attached Files"
                                          iconClass = "ri-attachment-line"
                                          isOpen={isOpen2}
                                          toggleCollapse={toggleCollapse2}
                                      >

                                          <AttachedFiles files={files} />
                                      </CustomCollapse>
                                  </Card> */}
                {/* End Attached Files card  */}
              </div>
            </SimpleBar>
            {/* end profile-user-accordion  */}
          </div>
          {/* end user-profile-desc  */}
        </div>
      )}
    </React.Fragment>
  );
}
