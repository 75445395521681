import React from "react";

// import BG from "../assets/images/bg-min.png";

export default function Background() {
  return (
    <React.Fragment>
      <div className="background" />
    </React.Fragment>
  );
}
