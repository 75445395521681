import { useContext } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { AuthContext } from "../Auth";

export const PrivateRoute = () => {
  let location = useLocation();
  const { currentUser } = useContext(AuthContext);

  // console.log("currentUser", currentUser)

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}
