import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import liff from "@line/liff";

import logo from "./assets/images/CR_Logo.jpg";
import Background from "./components/Background";

const Login = () => {
  const navigate = useNavigate();
  const [isLINEInitSuccess, setIsLINEInitSuccess] = useState(true);

  const signInWithLINE = () => {
    if (isLINEInitSuccess) {
      // Login
      liff.login();
      navigate('/information', { replace: true });
    } else {
      // LINE Init Failed
    }
  };

  useEffect(() => {
    // liff
    //   .init({
    //     liffId: process.env.REACT_APP_LINE_LIFF_ID,
    //   })
    //   .then(async () => {
    //     setIsLINEInitSuccess(true);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  return (
    <React.Fragment>
      <Background />
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="text-center mb-4">
                <div className="auth-logo mb-5 d-block">
                  <img
                    src={logo}
                    alt=""
                    height="150"
                    className="logo logo-dark"
                  />
                </div>

                <h4>{"โครงการรณรงค์แนะนำธรรมยุวชนประเทศไทย"}</h4>
                <p className="text-muted mb-4">
                  {"กรุณาเข้าสู่ระบบเพื่อบันทึกข้อมูล"}.
                </p>
              </div>
              <br />

              <div className="d-grid">
                <Button
                  block
                  id="line-btn"
                  className=" waves-effect waves-light"
                  onClick={signInWithLINE}
                >
                  {"เข้าสู่ระบบด้วย LINE"}
                </Button>
              </div>

              <div className="mt-5 text-center">
                <p>{"© Soka Gakkai Thailand 2022. All Rights Reserved."}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Login;