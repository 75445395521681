import { initializeApp } from "firebase/app";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment,
  getFirestore,
  collection,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

class FirebaseBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      this.db = getFirestore(app);

      // console.log("Initialize Firebase Success");
    }
  }

  async getUserInfo(uId) {
    let userData = null;

    const docRef = doc(this.db, "youth-record", uId);
    // const docRef2 = this.db.
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      userData = docSnap.data();
    } else {
      console.log("No user found on getUserInfo()!");
    }

    return userData;
  }

  async registerUser(uId, userName, profileImage, rumpak, pak, kate, firstname, lastname, division) {
    const userRef = collection(this.db, "youth-record");
    await setDoc(doc(userRef, uId), {
      userName: userName,
      profileImage: profileImage,
      lineId: uId,
      rumpak: rumpak,
      pak: pak,
      kate: kate,
      daimoku: 0,
      inviteAmount: 0,
      inviteName: [],
      firstname: firstname,
      lastname: lastname,
      division: division,
    });

    console.log("Document written registerUser");
    return true;
  }

  async updateFirstname(uId, firstname) {
    const userRef = doc(this.db, "youth-record", uId);
    await updateDoc(userRef, {
      firstname: firstname,
    });

    console.log("Document updateFirstname");
    return true;
  }

  async updateLastname(uId, lastname) {
    const userRef = doc(this.db, "youth-record", uId);
    await updateDoc(userRef, {
      lastname: lastname,
    });

    console.log("Document updateLastname");
    return true;
  }

  async updateDivision(uId, division) {
    const userRef = doc(this.db, "youth-record", uId);
    await updateDoc(userRef, {
      division: division,
    });

    console.log("Document updateLastname");
    return true;
  }

  async recordDaimoku(uId, daimoku) {
    const userRef = doc(this.db, "youth-record", uId);
    await updateDoc(userRef, {
      daimoku: increment(daimoku),
    });

    console.log("Document increment daimoku: ", daimoku);
    return true;
  }

  async recordInviteAmount(uId, inviteAmount) {
    const userRef = doc(this.db, "youth-record", uId);
    await updateDoc(userRef, {
      inviteAmount: increment(inviteAmount),
    });

    console.log("Document increment inviteAmount: ", inviteAmount);
    return true;
  }

  async recordInviteName(uId, inviteInfo) {
    const inviteName = {
      name: inviteInfo.name, // required field
      nickname: inviteInfo.nickname ? inviteInfo.nickname : "",
      tel: inviteInfo.tel ? inviteInfo.tel : "",
      age: inviteInfo.age ? inviteInfo.age : "",
      address: inviteInfo.address ? inviteInfo.address : "",
    };

    const userRef = doc(this.db, "youth-record", uId);
    await updateDoc(userRef, {
      inviteName: arrayUnion(inviteName),
    });

    console.log(
      "Document update elements in an array inviteName: ",
      inviteName
    );
    return true;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
